import React, { useRef } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const projects = require("../projects.json");

const Home = () => {
  const projectRef = useRef(null);
  const contactRef = useRef(null);
  const expRef = useRef(null);
  const aboutRef = useRef(null);

  const projectScroll = () => {
    projectRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const contactScroll = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const topScroll = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const aboutScroll = () => {
    aboutRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const expScroll = () => {
    expRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <Navbar
        id="navbar"
        collapseOnSelect
        relative="top"
        expand="sm"
        variant="dark"
        sticky="top"
      >
        <Navbar.Brand>
          <img
            src="gayatri.png"
            alt="logo"
            height="50"
            onClick={topScroll}
            style={{ cursor: "pointer" }}
          />
        </Navbar.Brand>
        <Nav.Link id="nav-icon" href="https://github.com/Gittss">
          <i id="nav-iconn" className="fa fa-github fa-2x"></i>
        </Nav.Link>
        <Nav.Link
          id="nav-icon"
          href="https://www.linkedin.com/in/gayatri-0548371a9"
        >
          <i id="nav-iconn" className="fa fa-linkedin fa-2x"></i>
        </Nav.Link>
        <Nav.Link
          id="nav-icon"
          href="https://mail.google.com/mail/?view=cm&fs=1&to=gits1399@gmail.com&su=SUBJECT&body=BODY&bcc=someone.else@example.com"
        >
          <i id="nav-iconn" className="fa fa-google fa-2x"></i>
        </Nav.Link>
        <Nav.Link id="nav-icon" href="https://www.instagram.com/gayatri.in">
          <i id="nav-iconn" className="fa fa-instagram fa-2x"></i>
        </Nav.Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Container id="nav-collapse">
            <Nav>
              <Nav.Link id="nav-collapse" onClick={aboutScroll}>
                About
              </Nav.Link>
              <Nav.Link id="nav-collapse" onClick={expScroll}>
                Experiences
              </Nav.Link>
              <Nav.Link id="nav-collapse" onClick={projectScroll}>
                Works
              </Nav.Link>
              <Nav.Link id="nav-collapse" onClick={contactScroll}>
                Collabrate
              </Nav.Link>
            </Nav>
          </Container>
        </Navbar.Collapse>
      </Navbar>

      <div>
        <div id="picCol">
          <img
            className="img-thumbnail img-responsive"
            src="https://sdk.bitmoji.com/render/panel/79ba5f12-d2e2-4192-a5ae-1ed8fd818d82-cbde2559-8f73-49ee-ba82-36a11f8b59fd-v1.png?transparent=1&palette=1"
            alt="pp"
            height="300"
            width="300"
          />
        </div>
      </div>

      <div
        id="about"
        ref={aboutRef}
        className="mx-auto"
        style={{
          paddingTop: "75px",
          backgroundColor: "black",
          color: "rgb(255, 116, 23)",
        }}
      >
        <div className="row h-100 w-100 mx-auto">
          <div className="col-md-6 col-sm-6 p-4 zoom">
            <h3 id="name">
              <img
                src="https://sdk.bitmoji.com/render/panel/a5122ff9-432a-4e6b-9179-4a54fcd4f64c-cbde2559-8f73-49ee-ba82-36a11f8b59fd-v1.png?transparent=1&palette=1"
                width="60"
                height="60"
              />
              Professional
            </h3>
            <p>
              I have more than three years of experience as an Associate I
              Software Engineer with S&P Global.
              <br />
              In my time here, I've already contributed to over a dozen projects
              and assisted with managing one project for one of the firm's
              long-time clients
              <br />
              <br />
              <li>
                In my early career I've worked on live projects as a
                backend-intern at various firms and startups.
              </li>
              <li>
                Javascript (Node.js), React.js, python and C++ are the major
                languages that I code in.
              </li>
              <li>
                I have worked in Agile methodologies using Scrum and deployments
                through Jenkins.
              </li>
              <br />I am the co-founder of Quoteag, a social platform for
              sharing thoughts and quotes.
            </p>
          </div>
          <div className="col-md-6 col-sm-6 p-4 zoom">
            <h3 id="name">
              <img
                src="https://sdk.bitmoji.com/render/panel/5479aa75-4dc9-4d4b-944c-c961e3831d6f-cbde2559-8f73-49ee-ba82-36a11f8b59fd-v1.png?transparent=1&palette=1"
                width="60"
                height="60"
              />
              Personal
            </h3>
            <p>
              I am born and brought up in Delhi. My personal interests include
              driving and coding. I relax well while having a steering wheel in
              my hands. My best way to spend time is to code and develop
              software projects of various types. According to me a person
              should be the master of his own brain. And yes, I like to quote a
              lot ;)
            </p>
          </div>
        </div>

        <div
          ref={expRef}
          style={{
            backgroundColor: "black",
            height: "fit-content",
            paddingTop: "80px",
            color: "rgb(255, 116, 23)",
          }}
        >
          <h2 id="name" className="p-4">
            Work Experiences
          </h2>
          <div className="row w-100 mx-auto d-flex justify-content-center">
            <div className="p-3 col-md-3 col-sm">
              <h4>S&P Global</h4>
              <p>
                Associate I software engineer in Markit Digital's feature team.
                (present)
              </p>
              <small>technologies : </small>
              <span className="badge badge-pill badge-light">React.js</span>
              &ensp;
              <span className="badge badge-pill badge-light">Redux</span>
              &ensp;
              <span className="badge badge-pill badge-light">.Net MVC</span>
              &ensp;
              <span className="badge badge-pill badge-light">Jenkins</span>
              &ensp;
              <span className="badge badge-pill badge-light">Jira</span>
              &ensp;
              <span className="badge badge-pill badge-light">DAG-DM</span>
              &ensp;
              <span className="badge badge-pill badge-light">Node</span>
            </div>
            <div className="col-md-3 col-sm p-3">
              <h4>Bit Brothers</h4>
              <p>
                Backend intern under the founder of the company. Worked on
                multiple projects (Startup Mumbai App)
              </p>
              <small>technologies : </small>
              <span className="badge badge-pill badge-light">Mongoose</span>
              &ensp;
              <span className="badge badge-pill badge-light">Express</span>
              &ensp;
              <span className="badge badge-pill badge-light">Node.js</span>
              &ensp;
              <span className="badge badge-pill badge-light">Handlebars</span>
            </div>
            <div className="col-md-3 col-sm p-3">
              <h4>NIC Data Centre</h4>
              <p>
                Project team lead intern under the director of engineering
                department.
              </p>
              <small>technologies : </small>
              <span className="badge badge-pill badge-light">Java</span>&ensp;
              <span className="badge badge-pill badge-light">Spring MVC</span>
            </div>
          </div>
        </div>

        <div
          ref={projectRef}
          style={{
            backgroundColor: "black",
            height: "fit-content",
            paddingTop: "80px",
            color: "rgb(255, 116, 23)",
          }}
        >
          <h2 id="name" className="p-2">
            Few of My Works
          </h2>
          <div className="mx-auto p-3 row h-100 w-100 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
            {projects.map((project, i) => (
              <div className="col-6">
                <a href={project.link} style={{ textDecoration: "none" }}>
                  <div
                    className="card zoom"
                    style={{
                      backgroundImage: `url(${project.imageUrl})`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="ctext">
                      <h3 className="ctitle">{project.name}</h3>
                      <p id="cardtext" className="cardtext">
                        {project.desc}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
        <div
          ref={contactRef}
          style={{
            backgroundColor: "black",
            height: "100vh",
            paddingTop: "80px",
            color: "rgb(255, 116, 23)",
          }}
        >
          <h2 id="name" className="p-2">
            Collabrate with Me
          </h2>
          <h5 id="desc" className="p-3">
            I am available for projects, if you wish to collabrate drop me a
            mail <br />
            <h4>
              <a
                id="mailcolor"
                href="https://mail.google.com/mail/?view=cm&fs=1&to=gits1399@gmail.com&su=SUBJECT&body=BODY&bcc=someone.else@example.com"
              >
                gits1399@gmail.com
              </a>
            </h4>
            <br />
            <br />
            <br />
            <a
              id="nav-iconn"
              href="https://drive.google.com/file/d/16bYKWRVA5T4tOfYOCw6AHn6fuojJWbZH/view?usp=sharing"
              className="fa-blink"
            >
              Curriculum vitae <i className="fa fa-file"></i>
            </a>
          </h5>
        </div>
      </div>
    </>
  );
};

export default Home;
